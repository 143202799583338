import "./App.scss";
import Home from "./pages/home/home";

function App() {
	return (
		<div>
			<Home />
		</div>
	);
}

export default App;

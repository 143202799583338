import HeroMain from "../../components/hero-main/hero-main";

function Home() {
	return (
		<div>
			<HeroMain />
		</div>
	);
}
export default Home;
